import React, {useEffect, useState} from "react";
import App from "./App";
import './i18n'
import {useTranslation} from "react-i18next";
import {changeLocale} from "./Api";

export default function LocalizedApp() {

    const {i18n} = useTranslation('translation');
    let urlLang = window.location.pathname.replace("/", "");
    const [lang, setLang] = useState(urlLang)

    useEffect(() => {
        let lng = window.location.pathname;
        i18n.changeLanguage(lng);
        changeLocale(lng)
    },[i18n]);

    return (
        <App lang={lang}/>
    )
}