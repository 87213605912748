import * as React from 'react';
import {Button, Paper, Stack, TextField, Typography} from "@mui/material";

const HavingIssues = ({onSubmit,onClose}) => {

    return (
        <Paper elevation={10}>
                <Typography >
                    Estamos recibiendo muchas peticiones en estos momentos, déjanos tu email y te mandaremos la respuesta en cuanto la tengamos.
                </Typography>
                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                    <TextField id="outlined-basic" label="email" variant="outlined"/>
                    <Button variant="contained" onClick={onSubmit}>Vale</Button>
                    <Button variant="contained" onClick={onClose}>No, gracias</Button>
                </Stack>
        </Paper>

    )
}

export default HavingIssues;