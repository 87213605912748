import {ThemeProvider} from '@mui/material/styles'
import puntro, {backgroundImage} from './theme'
import {Box, CssBaseline, Grid, MenuItem, Select} from "@mui/material";
import PuntroMain from "./PuntroMain";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import React, {useEffect, useMemo, useState} from "react";
import Footer from "./Footer";
import {Flight} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import countryList from 'react-select-country-list'
import {changeCurrency, changeLocale, changeMarket} from './Api'
import LocaleCurrency from 'locale-currency'
import {Link} from "react-router-dom";
import LocalizedApp from "./LocalizedApp";

const useStyles = makeStyles({
    root: {}
});

function App() {

    const classes = useStyles();
    const {t, i18n} = useTranslation('translation');
    let urlLang = window.location.pathname.replace("/", "");
    const [lang, setLang] = useState(urlLang)
    const [country, setCountry] = useState('ES')
    const countries = useMemo(() => countryList().getData(), [])
    const [currency, setCurrency] = useState(LocaleCurrency.getCurrency(country));

    useEffect(() => {
    }, [LocalizedApp])

    function bacgkground() {
        return <Box sx={{
            position: "fixed",
            width: "100%",
            height: "500px",
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            padding: "10px"
        }}/>;
    }

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        setLang(event.target.value);
        console.log(lang)
        changeLocale(event.target.value)
    };

    const changeCountry = event => {
        setCountry(event.target.value);
        let newCurrency = LocaleCurrency.getCurrency(event.target.value);
        setCurrency(newCurrency);
        changeMarket(event.target.value);
        changeCurrency(newCurrency);
    }

    function header() {
        return <Box position="sticky" width="100%">
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="secondary"
                        aria-label="menu"
                        sx={{mr: 2}}
                    >
                        <Flight/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Puntro
                    </Typography>

                    {<>{currency}</>}
                    <Select
                        onChange={changeCountry}
                        value={country}>
                        {
                            countries.map(value => (<MenuItem value={value.value}>{value.label}</MenuItem>))
                        }
                    </Select>
                    <Select
                        value={lang}
                        onChange={changeLanguage}
                    >
                        <MenuItem value={'es'}><Link to="/es">Español</Link></MenuItem>
                        <MenuItem value={'en'}><Link to="/en">English</Link></MenuItem>
                    </Select>
                </Toolbar>
            </AppBar>
        </Box>;
    }

    function title() {
        return <Box sx={{
            position: "sticky",
            alignContent: "left",
            padding: "10px",
        }} width='100%'>
            <Typography variant="h1">{t('title')}</Typography>
        </Box>;
    }

    return (
        <ThemeProvider theme={puntro}>
            <CssBaseline/>
            <Grid container>
                {bacgkground()}
                {header()}
                {title()}
                <PuntroMain/>
                <Footer/>
            </Grid>
        </ThemeProvider>
    );
}

export default App;
