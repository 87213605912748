import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import LocalizedApp from "./LocalizedApp";

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Navigate replace to="/es" />} />
            <Route path="/es" element={<LocalizedApp/>}/>
            <Route path="/en" element={<LocalizedApp/>}/>
        </Routes>
    </BrowserRouter>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();