import * as React from 'react';
import {Autocomplete, TextField} from "@mui/material";
import {requestAirports} from "./Api";
import {useState} from "react";



const MultiplePlaceSelector = ({onSelected, multiple, label})=> {

    const [airportsText, setAirportsText] = useState([]);


    const getSuggestions = (value) => {
        requestAirports(value).then(value => {
            setAirportsText(value);
        });
    }

    return (<Autocomplete
        limitTags={1}
        variant={"outlined"}
        multiple={multiple}
        disablePortal
        options={airportsText}
        sx={{minWidth: 300}}
        onChange={(e,values) => {
            if (Array.isArray(values)){
                const replaced = values
                    .map(value => value.replace("-sky", ""))
                    .map(value => value.substring(value.length-4,value.length-1))
                onSelected(replaced);
            } else {
                let tmp = values?.replace("-sky", "");
                tmp = tmp.substring(tmp?.length-4,tmp?.length-1);
                onSelected(tmp);
            }

        }}
        onInputChange={(event,value, reason) => {
            if (reason === 'input') {
                getSuggestions(value);
            }
        }}
        renderInput={(params) => <TextField {...params} label={label}/>}
    />)
}

export default MultiplePlaceSelector;