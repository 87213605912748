import * as React from 'react';
import {Box, Button, Card, CardContent, Grid, Link, Paper, Typography} from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {styled} from "@mui/material/styles";


const FlightDetail = ({flight}) => {

    const Item = styled(Box)(({theme}) => ({
        textAlign: 'center',
        verticalAlign: 'center',
    }));

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: "440px"}}>

            <Paper>
                <Grid container>
                    <Grid item xs={12}>
                        <Item>
                            <Typography variant="body1" color="text.primary" component="div">
                                Vuelo desde {flight.origin.name}
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={5}>
                        <Item>
                            <Typography component="div" color="text.primary" variant="h5">
                                {flight.origin.searchCode}
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={2}>
                        <ArrowRightAltIcon color="secondary"/>
                    </Grid>
                    <Grid item xs={5}>
                        <Item>
                            <Typography component="div" color="text.secondary" variant="h5">
                                {flight.destination.searchCode}
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>
                            <Typography variant="body2" color="text.secondary" component="div">
                                {flight.outbound.localDateTime}
                            </Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Paper>


            <Paper>
                <Grid container>
                    <Grid item xs={5}>
                        <Item>
                            <Typography component="div" color="text.secondary" variant="h5">
                                {flight.destination.searchCode}
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={2}>
                        <ArrowRightAltIcon color="secondary"/>
                    </Grid>
                    <Grid item xs={5}>
                        <Item>
                            <Typography component="div" color="text.primary" variant="h5">
                                {flight.origin.searchCode}
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>
                            <Typography variant="body2" variant="body2" color="text.secondary" component="div">
                                {flight.inbound.localDateTime}
                            </Typography>
                        </Item>
                    </Grid>


                    <Grid item xs={12}>
                        <Item>
                            <Link href={flight.referral} target="_blank" underline="none">
                                <Button color="secondary" variant="contained">
                                    {flight.amount.amount} €
                                </Button>
                            </Link>
                            <br /><br />
                        </Item>
                    </Grid>
                </Grid>
            </Paper>

        </Box>)

}


export default FlightDetail;