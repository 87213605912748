import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";

export default function BasicDateRangePicker({onSelected}) {
    const [value, setValue] = React.useState([null, null]);
    const {t} = useTranslation('translation');

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                disablePast={true}
                startText={t('search.label.from-date')}
                endText={t('search.label.to-date')}
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    onSelected(newValue);
                }}
                renderInput={(startProps, endProps) => {
                    const start = {...startProps};
                    const end = {...endProps};

                    return (
                        <React.Fragment>
                            <TextField {...start} />
                            <Box sx={{mx: 2}}> a </Box>
                            <TextField {...end} />
                        </React.Fragment>
                    );
                }}
            />
        </LocalizationProvider>
    );
}