import * as React from "react";
import {Box, Grid, Typography} from "@mui/material";

export default function Footer() {
    return (
        <Box position="fixed" sx={{top: 'auto', bottom: 0, backgroundColor: "primary.main", width: "100%"}}>
            <Grid container>
                <Grid item container xs={5} xm={4} justifyContent={"left"}>
                    <Box sx={{justifyContent: 'flex-end'}}>
                        <Typography variant="b1" color="white">@ 2021 Puntro</Typography>
                    </Box>
                </Grid>

                <Grid item container xs={2} xm={4}>
                </Grid>

                <Grid item container xs={5} xm={4} justifyContent="right">
                    <Box container sx={{maxWidth: 250}}>
                        {/*<img src="powered-by/SVG/inline--white.svg" width='100%'/>*/}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}