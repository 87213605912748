import React from 'react'
import Typography from '@mui/material/Typography';
import {Box, Paper, Stack} from "@mui/material";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {styled} from '@mui/material/styles';
import theme from "./theme";
import {useTranslation} from "react-i18next";

const Instructions = () => {


    const {t} = useTranslation('translation');
    const Item = styled(Paper)(({theme}) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        maxWidth: '300px',
    }));


    return (
        <>
            <Stack direction={{xs: 'column', sm: 'row', backgroundColor: theme.palette.primary.main}} spacing={2}>
                <Item elevation={3}>
                    <LocationCityIcon fontSize="large"/>
                    <Typography gutterBottom variant="h5" component="div">
                        {t('help.step1.title')}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {t('help.step1.value')}
                    </Typography>
                </Item>
                <Item elevation={3}>
                    <DateRangeIcon fontSize="large"/>
                    <Typography gutterBottom variant="h5" component="div">
                        {t('help.step2.title')}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {t('help.step2.value')}
                    </Typography>
                </Item>
                <Item elevation={3}>
                    <FlightTakeoffIcon fontSize="large"/>
                    <Typography gutterBottom variant="h5" component="div">
                        {t('help.step3.title')}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {t('help.step3.value')}
                    </Typography>
                </Item>
            </Stack>
        </>
    )

}

export default Instructions;
