import * as React from 'react';
import {useState} from 'react';
import FlightSeeker from "./FlightSeeker";
import FlightsList from "./FlightsList";
import {Box, Stack, Tab} from "@mui/material";
import HavingIssues from "./HavingIssues";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Instructions from "./Instructons";
import mockFlights from "./MockFlights";
import {useTranslation} from "react-i18next";


const PuntroMain = () => {

    const {t} = useTranslation('translation');
    const [flightResult, setFlightResult] = useState([]);
    // const [flightResult, setFlightResult] = useState(mockFlights);
    const [searchError, setSearchError] = useState(false);
    const [selectedTab, setSelectedTab] = useState('1');
    const [tabValue, setTabValue] = React.useState('one');
    const [origins, setOrigins] = React.useState([{}]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Stack>
            <Box sx={{position: 'sticky', width: '100%', typography: 'body1',}}>
                <TabContext value={selectedTab}>
                    <TabList onChange={(event, newValue) => setSelectedTab(newValue)}>
                        <Tab sx={{
                            backgroundColor: 'secondary.light',
                            opacity: '1',
                            borderRadius: 2,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0
                        }} label={t('search.wherever')} value="1"/>
                        <Tab sx={{
                            backgroundColor: 'secondary.light',
                            opacity: '1',
                            borderRadius: 2,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0
                        }} label={t('search.with-destination')} value="2"/>
                    </TabList>
                    <TabPanel value="1" sx={{
                        backgroundColor: 'secondary.light',
                        opacity: '1',
                        borderRadius: 2,
                        borderTopLeftRadius: 0
                    }}>
                        <FlightSeeker onFlightResult={(val) => {
                            setFlightResult(val);
                        }} onSearchError={() => {
                            setSearchError(true)
                        }} withDestination={false}/>
                    </TabPanel>

                    <TabPanel value="2" sx={{
                        backgroundColor: 'secondary.light',
                        opacity: '1',
                        borderRadius: 2,
                        borderTopLeftRadius: 0
                    }}>
                        <FlightSeeker onFlightResult={(val) => {
                            setFlightResult(val);
                        }} onSearchError={() => {
                            setSearchError(true)
                        }} withDestination={true}/>
                    </TabPanel>
                </TabContext>
                <br/><br/>
            </Box>

            <Box sx={{width: '100%', typography: 'body1'}}>
                {flightResult?.length === 0 || <><FlightsList flightList={flightResult}/><br/><br/></>}
            </Box>

            <Box position="sticky" sx={{width: '100%', typography: 'body1'}}
                 display="flex"
                 justifyContent="center"
            >
                {<><Instructions/><br/><br/></>}
            </Box>

            {searchError && <HavingIssues onClose={() => setSearchError(false)}/>}
        </Stack>
    );
}

export default PuntroMain;