import * as React from 'react';
import {useState} from 'react';
import {Box, Button, Paper, Stack} from "@mui/material";
import MultiplePlaceSelector from "./MultiplePlaceSelector";
import MultiplePlaceWithPassengersSelector from "./MultiplePlaceWithPassengersSelector"
import {findFlights} from "./Api";
import moment from "moment";
import BasicDateRangePicker from "./BasicDateRangePicker";
import theme from "./theme";
import {useTranslation} from "react-i18next";


const FlightSeeker = ({onFlightResult, onSearchError, withDestination}) => {

    const {t} = useTranslation('translation');
    const [origins, setOrigins] = useState([]);
    const [passengers, setPassengers] = useState([]);
    const [destination, setDestination] = useState("");
    const [outboundDate, setOutboundDate] = useState();
    const [inboundDate, setInboundDate] = useState();

    //moment(Date.now()).format("MM-DD-yyyy")
    //moment(Date.now(), "DD-MM-yyyy").add(5, 'days')

    const onDateRangeSelected = (value) => {
        if (value[0])
            setOutboundDate(moment(value[0]).format("yyyy-MM-DD"))

        if (value[1])
            setInboundDate(moment(value[1]).format("yyyy-MM-DD"))
    }

    const onOriginsAndPassengersSelected = (originsAndPassengers) => {
        const origins = originsAndPassengers.map(v => v.origin)
            .map(v => v.replace("-sky", "").substring(v.length-4,v.length-1));
        setOrigins(origins);
        const passengers = originsAndPassengers.map(v => v.passengers);
        setPassengers(passengers);
    }

    const searchButtonDisabled = !(origins.length >= 0 && outboundDate !== undefined && inboundDate !== undefined);


    const searchFlights = () =>{
        findFlights(origins, passengers, destination, outboundDate, inboundDate)
        .then(value => {
            onFlightResult(value)
        }).catch(onSearchError);
    }

    return (
        <>
            <Paper elevation={3}>
                <Box sx={{padding: "10px 10px 10px 10px", }}>
                    <Stack direction={{xs: 'column', sm: 'row', backgroundColor:theme.palette.primary.main}} spacing={2}>
                        <MultiplePlaceWithPassengersSelector multiple={true} 
                                               label={t('search.label.origin')}
                                               onSelected={(val) => onOriginsAndPassengersSelected(val)}/>

                        {withDestination && <MultiplePlaceSelector multiple={false} 
                                                                   label={t('search.label.destination')}
                                                                   onSelected={(val) => setDestination(val)}/>}

                        <BasicDateRangePicker onSelected={onDateRangeSelected}/>
                        <Button disabled={searchButtonDisabled} variant="contained" onClick={searchFlights}>{t('search.label.search')}</Button>
                    </Stack>

                </Box>
            </Paper>

        </>)

}

export default FlightSeeker;