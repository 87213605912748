import axios from "axios";

// let BASE_URL = `http://localhost:8080/puntro/v1`;
let BASE_URL = `https://puntro-4j-back.onrender.com/puntro/v1`
let country = 'ES';
let locale = 'es';
let currency = 'EUR';
export const requestAirports = async (query) => {

    const res = await axios.get(`${BASE_URL}/airports?query=${query}&locale=${locale}`);
    return res?.data?.map(place => `${place.displayName}`) || [];
}

export const findFlights = async (origins,adults,destination, outboundDate, inboundDate) => {
    const res = await axios.get(`${BASE_URL}/flights?origins=${origins}&adults=${adults}&destination=${destination}&outboundDate=${outboundDate}&inboundDate=${inboundDate}&locale=${locale}&market=${country}&currency=${currency}`);
    return res?.data || [];
}

export const changeMarket = (newMarket) => {
    country = newMarket;
}

export const changeLocale = (newLocale) => {
    locale = newLocale;
}

export const changeCurrency = (newCurrency) => {
    currency = newCurrency;
}
