import * as React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlightDetail from "./FlightDetail";
import _ from 'lodash';


const MatchDetail = ({match}) => {


    const totalpassengersArray = match.flights.map(f => f.passengers.adults);
    const totalPassengers = _.sum(totalpassengersArray);
    const totalPerPassenger = parseFloat(match.totalAmount/totalPassengers).toFixed(2);

    return (
        <Accordion sx={{width:'100%'}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id={match.destination.id}
            >
                <Typography variant="h5" color="text.primary" component="div">{`${match.totalAmount}€ a ${match.destination.name} - ${totalPerPassenger}€/persona.`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                    <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                        {match.flights.map(flight => {
                            return <FlightDetail flight={flight}/>
                        })}
                    </Stack>
            </AccordionDetails>

        </Accordion>)
}
export default MatchDetail;