import * as React from 'react';
import {Autocomplete, TextField, Grid, Box, IconButton} from "@mui/material";
import {requestAirports} from "./Api";
import {useState} from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { styled } from '@mui/material/styles';

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const MultiplePlaceWithPassengersSelector = ({onSelected, multiple, label})=> {

    const [airportsText, setAirportsText] = useState([]);
    const [selectedPlaces, setSelectedPlaces] = useState({});

    const getSuggestions = (value) => {
        requestAirports(value).then(value => {
            setAirportsText(value);
        });
    }

    const incrementPassengers= (event, origin)=>{
        selectedPlaces[origin]=selectedPlaceNumber(origin)+1;
        if(selectedPlaces[origin] > 9){
            selectedPlaces[origin]=9;
            event.stopPropagation();
        }
        if(selectedPlaces[origin] !== 1){
            event.stopPropagation();
        }

        setSelectedPlacesWrapper({...selectedPlaces});
    }

    const setPassentgers = (event, origin, number)=>{
        selectedPlaces[origin]=number;       
        setSelectedPlacesWrapper({...selectedPlaces});
    }

    const decrementPassengers=(event, origin)=>{
        selectedPlaces[origin]=selectedPlaceNumber(origin)-1;
        if(selectedPlaces[origin] < 0){
            selectedPlaces[origin]=0;
            event.stopPropagation();
        }
        
        if(selectedPlaces[origin] != 0){
            event.stopPropagation();
        }

        setSelectedPlacesWrapper({...selectedPlaces});
    }


    const selectedPlaceNumber = (place)=>{
       return selectedPlaces[place] || 0;
    }

    const setSelectedPlacesWrapper = (value) => {  
        setSelectedPlaces(value);

        onSelected(Object.keys(value)
            .map(key => (
                    {
                        origin:key,
                        passengers:value[key]
                    }
                )
            )
        );
    }

    return (<Autocomplete
        limitTags={1}
        variant={"outlined"}
        multiple={multiple}
        disableCloseOnSelect
        options={airportsText}
        sx={{minWidth: 300}}
        getOptionLabel={(option) => {
    
            return `${selectedPlaceNumber(option)} x ${option}`;
        }}
        
        renderOption={(props, option, { selected }) => {
            
            return (
                <li {...props}  onClick={(event)=> {
                                    if (selectedPlaceNumber(option)==0){
                                        if(!selected){
                                            incrementPassengers(event, option);
                                        }
                                    }
                                    if(selected){
                                        setPassentgers(event, option, 0);
                                    }
                                    props.onClick(event);             
                                }
                    }>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <Item>{option}</Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item>
                                <IconButton onClick={(event)=>decrementPassengers(event, option)}>
                                    <RemoveCircleIcon />
                                </IconButton> 
                                {selectedPlaceNumber(option)}
                                <IconButton onClick={(event)=>incrementPassengers(event, option)}><AddCircleIcon/></IconButton>
                            </Item>
                        </Grid>
                    </Grid>
             </li>
          )}
        }
        
        onInputChange={(event,value, reason) => {
            if (reason === 'input') {
                getSuggestions(value);
            }
            
            if (reason === 'clear'){               
                setSelectedPlaces({});
            }
        }}
        renderInput={(params) => <TextField {...params} label={label}/>}
    />)
}

export default MultiplePlaceWithPassengersSelector;