import * as React from 'react';
import MatchDetail from "./MatchDetail";

const FlightsList = ({flightList}) => {

    return (
        <>
                {flightList.map(match => {
                    return <MatchDetail match={match}/>
                })}
        </>
    );
}


export default FlightsList;