import {createTheme, responsiveFontSizes} from '@mui/material/styles'


export const thirdColor ="#309e83";
export const fourthColor ="#d7eb8a";

export const backgroundImage = "people-g6f387e22a_1920.jpeg";
export const skyScannerPowredImage = "powered-by/SVG/stacked--blue.svg";
const maxWith = "1000px";
const puntro = responsiveFontSizes(createTheme({
    palette: {
        mode: 'light',
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#525661',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#309e83',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: 'colibri',
        color: 'white',
        h1: {
            fontSize: '3rem',
            color: 'white',
            // fontFamily:"colibri"
        }
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    justifyContent: "center",
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    margin: "0px 0 0 0",
                    // maxWidth: maxWith,
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    margin: "10px 10px 10px 10px",
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    margin: "0px",
                }
            }
        },
        MuiTabList:{
            styleOverrides: {
                root: {
                    font:{
                        color:"white",
                    },
                }
            }
        },
        MuiTab:{
            styleOverrides:{
                root:{
                    border:"1px solid",
                }
            }

        }


    },

}));
export default puntro;